import {yupResolver} from '@hookform/resolvers/yup'
import DataFormCDO from 'Components/Cdo/DataForm'
import FileFormCDO from 'Components/Cdo/FileForm'
import {BEA_DOC_KEYS} from 'Components/Cdo/helpers'
import BreadCrumb from 'Components/Common/BreadCrumb'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import {paymentMethods} from 'Helpers/options'
import {LIST_ALL_FORWADER_URL, LIST_ALL_WAREHOUSE_URL, LIST_SPPB_DOC_TYPE_ALL_URL} from 'Helpers/url_helper'
import {CdoRQ} from 'Hooks/Queries'
import {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

export const docTypes = {
  PLP:'PLP',
  SPPB: 'SPPB'
}
export default function EditCreateCdo(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const {nleToken, nleRefreshToken} = useSelector((state) => state.auth)
  const [docType, setDocType] = useState(docTypes.SPPB);

  const [selectedWarehouse, setSelectedWarehouse] = useState(null)
  const [selectedForwarder, setSelectedForwarder] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [warehouseOptions, setWarehouseOptions] = useState([])
  const [forwarderOptions, setForwarderOptions] = useState([])
  const [documentOptions, setDocumentOptions] = useState([])
  const [selectedDocument, setSelectedDocument] = useState([])
  const [plpValues, setPlpValues] = useState({ref_number:'', no_dokumen:''})


  const validation = Yup.object().shape({
    awb: Yup.string().required('AWB tidak boleh kosong'),
    hawb: Yup.string(),
    sppb: Yup.string(),
    do_no: Yup.string(),
    warehouse_id: Yup.string().required('Warehouse tidak boleh kosong'),
    sender_name: Yup.string().required('Nama Penerima tidak boleh kosong'),
    sender_phone: Yup.string().required('Phone number tidak boleh kosong'),
    sender_address: Yup.string().required('Alamat Pengirim tidak boleh kosong'),
    receiver_name: Yup.string().required('Nama Penerima tidak boleh kosong'),
    receiver_phone: Yup.string().required('Phone number tidak boleh kosong'),
    receiver_address: Yup.string().required(
      'Alamat Penerima tidak boleh kosong',
    ),
    koli: Yup.string().required('Koli tidak boleh kosong'),
    forwarder_id: Yup.string().required('Agent / forwader tidak boleh kosong'),
    type_of_goods: Yup.string().required('Jenis barang tidak boleh kosong'),
    weight: Yup.string().required('Berat tidak boleh kosong'),
    flight_date: Yup.string().required(
      'Tanggal penerbangan tidak boleh kosong',
    ),
    flight_code: Yup.string().required('Nomor penerbangan tidak boleh kosong'),
    invoice_no: Yup.string().required('Nomor kwitansi tidak boleh kosong'),
    invoice_date: Yup.string().required('Tanggal kwitansi tidak boleh kosong'),
    invoice_amount: Yup.string(),
    payment_method: Yup.string().required(
      'Metode pembayaran tidak boleh kosong',
    ),
    pu: Yup.string(),
    caw: Yup.string(),
    awb_file: Yup.mixed()
      .test('fileType', 'Unsupported File Format', (value) => {
        return (
          !value ||
          (value && value.length === 0) ||
          (value &&
            value.length > 0 &&
            ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type))
        )
      })
      .test('fileSize', 'The file is too large', (file) => {
        return (
          !file ||
          (file && file.length === 0) ||
          (file && file.length > 0 && file[0].size <= 2097152)
        )
      }),
    hawb_file: Yup.mixed()
      .test('fileType', 'Unsupported File Format', (value) => {
        return (
          !value ||
          (value && value.length === 0) ||
          (value &&
            value.length > 0 &&
            ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type))
        )
      })
      .test('fileSize', 'The file is too large', (file) => {
        return (
          !file ||
          (file && file.length === 0) ||
          (file && file.length > 0 && file[0].size <= 2097152)
        )
      }),
    sppb_file: Yup.mixed()
      .test('fileType', 'Unsupported File Format', (value) => {
        return (
          !value ||
          (value && value.length === 0) ||
          (value &&
            value.length > 0 &&
            ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type))
        )
      })
      .test('fileSize', 'The file is too large', (file) => {
        return (
          !file ||
          (file && file.length === 0) ||
          (file && file.length > 0 && file[0].size <= 2097152)
        )
      }),
    do_file: Yup.mixed()
      .test('fileType', 'Unsupported File Format', (value) => {
        return (
          !value ||
          (value && value.length === 0) ||
          (value &&
            value.length > 0 &&
            ['image/jpeg', 'image/png', 'image/jpg'].includes(value[0].type))
        )
      })
      .test('fileSize', 'The file is too large', (file) => {
        return (
          !file ||
          (file && file.length === 0) ||
          (file && file.length > 0 && file[0].size <= 2097152)
        )
      }),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      awb: '',
      awb_file: '',
      do_no: '',
      do_file: '',
      flight_code: '',
      flight_date: '',
      forwarder_id: '',
      hawb: '',
      hawb_file: '',
      invoice_date: '',
      invoice_no: '',
      invoice_amount: '',
      koli: '',
      payment_status: '',
      receiver_address: '',
      receiver_name: '',
      receiver_phone: '',
      sender_address: '',
      sender_name: '',
      sender_phone: '',
      sppb: '',
      sppb_file: '',
      type_of_goods: '',
      validation_status: '',
      warehouse_id: '',
      weight: 0,
      pu: '',
      caw: '',
    },
  }
  const {handleSubmit, formState, control, reset, register, setValue, watch} =
    useForm(formOptions)
  const {errors} = formState

  const {mutate: updateCdo, isLoading: loadingUpdate} = CdoRQ.useUpdateFormData(navigate)()

  const getPayload = (values) => {
    const formData = new FormData()
    const data = {
      awb: values.awb,
      hawb: values.hawb,
      sppb: values.sppb,
      do_no: values.do_no,
      warehouse_id: values.warehouse_id,
      sender_name: values.sender_name,
      sender_phone: values.sender_phone,
      sender_address: values.sender_address,
      receiver_name: values.receiver_name,
      receiver_address: values.receiver_address,
      receiver_phone: values.receiver_phone,
      forwarder_id: values.forwarder_id,
      koli: values.koli,
      weight: values.weight,
      type_of_goods: values.type_of_goods,
      flight_code: values.flight_code,
      flight_date: values.flight_date,
      invoice_date: values.invoice_date,
      invoice_no: values.invoice_no,
      invoice_amount: values.invoice_amount,
      payment_method: values.payment_method,
      pu: values.pu,
      caw: values.caw,
    }

    formData.append('doFile', values.do_file[0] ?? null)
    formData.append('hawb', values.hawb_file[0] ?? null)
    formData.append('spbb', values.sppb_file[0] ?? null)
    formData.append('awb', values.awb_file[0] ?? null)
    formData.append('data', JSON.stringify(data))
    return formData
  }
  const _updateCdo = (values) => {
    const merge = getPayload(values)
    merge.append('id', id)
    updateCdo(merge)
  }

  const {mutate: addCdo, isLoading: loadingCreate} = CdoRQ.useAddData(navigate)()

  const _createCdo = (values) => {
    addCdo(getPayload(values))
  }

  const {data: detailData, error, isFetching, status} = CdoRQ.useGetData(id)()

  const flyDateRef = useRef()
  const kwitansiDateRef = useRef()
  const plpDateRef = useRef()
  const tglDocumentRef = useRef()

  const getAllSppbDoc = () => {
    apiBearer.get(LIST_SPPB_DOC_TYPE_ALL_URL)
    .then(response=>{
      const {data} = response
      if(data.data)
        setDocumentOptions(data.data?.map((v)=>({label:v.name, value:v.code, date_required:v.date_required})))
    })
  }

  useEffect(() => {
    if (flyDateRef.current) {
      flatpickr(flyDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue('flight_date', sDate)
        },
      })
    }
    if (kwitansiDateRef.current) {
      flatpickr(kwitansiDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue('invoice_date', sDate)
        },
      })
    }
    if (plpDateRef.current) {
      flatpickr(plpDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          plpDateRef.current.value = sDate
        },
      })
    }
    if (tglDocumentRef.current && selectedDocument.date_required) {
      flatpickr(tglDocumentRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          tglDocumentRef.current.value = sDate
        },
      })
    }
  }, [docType, selectedDocument])


  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        sppb: detailData.data?.sppb ?? '',
        awb: detailData.data?.awb ?? '',
        do_no: detailData.data?.do_no ?? '',
        awb_file: [],
        do_file: [],
        flight_code: detailData.data?.flight_code ?? '',
        flight_date: detailData.data?.flight_date ?? '',
        forwarder_id: detailData.data?.forwarder_id ?? '',
        warehouse_id: detailData.data?.warehouse_id ?? '',
        hawb: detailData.data?.hawb ?? '',
        hawb_file: [],
        invoice_date: detailData.data?.invoice_date ?? '',
        invoice_no: detailData.data?.invoice_no ?? '',
        invoice_amount: detailData.data?.invoice_amount ?? '',
        koli: detailData.data?.koli ?? '',
        payment_status: detailData.data?.payment_status ?? '',
        payment_method: detailData.data?.payment_method ?? '',
        receiver_address: detailData.data?.receiver_address ?? '',
        receiver_name: detailData.data?.receiver_name ?? '',
        receiver_phone: detailData.data?.receiver_phone ?? '',
        sender_address: detailData.data?.sender_address ?? '',
        sender_name: detailData.data?.sender_name ?? '',
        sender_phone: detailData.data?.sender_phone ?? '',
        sppb_file: [],
        type_of_goods: detailData.data?.type_of_goods ?? '',
        validation_status: detailData.data?.validation_status ?? '',
        // warehouse_id: detailData.data?.warehouse_id ?? "",
        weight: detailData.data?.weight ?? '',
        pu: detailData.data?.pu ?? '',
        caw: detailData.data?.caw ?? '',
      })

      if (detailData?.data?.invoice_date && kwitansiDateRef.current) {
        setValue('invoice_date', detailData?.data?.invoice_date ?? new Date())
        kwitansiDateRef.current.value =
          detailData?.data?.invoice_date ?? new Date()
      }
      if (detailData?.data?.flight_date && flyDateRef.current) {
        setValue('flight_date', detailData?.data?.flight_date ?? new Date())
        flyDateRef.current.value = detailData?.data?.flight_date ?? new Date()
      }
      if (detailData?.data?.payment_method)
        setSelectedPaymentMethod(
          paymentMethods.find(
            ({value}) => value === detailData?.data?.payment_method,
          ),
        )
      searchWarehouse()
      searchForwader()
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching, kwitansiDateRef.current, flyDateRef.current])

  useEffect(() => {
    if(!id){
      searchWarehouse()
      searchForwader()
      getAllSppbDoc()
    }
  }, [])

  const searchForwader = async (input, refCategoryId) => {
    try {
      const result = await apiBearer.get(LIST_ALL_FORWADER_URL)
      const {data} = result
      if (data.success) {
        setForwarderOptions(
          data.data.map((v) => ({label: v.name, value: v.id})),
        )
        if (detailData?.data?.forwarder_id) {
          const forwarder = data.data.find(
            (v) => v.id === detailData.data.forwarder_id,
          )
          setSelectedForwarder({label: forwarder.name, value: forwarder.id})
        }
      }
    } catch (error) {}
  }

  const searchWarehouse = async (input, refCategoryId) => {
    try {
      const result = await apiBearer.get(LIST_ALL_WAREHOUSE_URL)
      const {data} = result
      if (data.success) {
        setWarehouseOptions(
          data.data.map((v) => ({label: v.name, value: v.id})),
        )
        if (detailData?.data?.warehouse_id) {
          const warehouse = data.data.find(
            (v) => v.id === detailData.data.warehouse_id,
          )
          setSelectedWarehouse({label: warehouse.name, value: warehouse.id})
        }
      }
    } catch (error) {}
  }

  const [isSeachingNLE, setIsSeachingNLE] = useState(false)
  const no_dokumen = watch().sppb
  
  const onSearchNLE = nleToken ? async () => {
        setIsSeachingNLE(true)
        try {
          let url = '/nle/get-plp'
          const params = {}
          params.nle_token= nleToken
          params.no_dokumen = no_dokumen
          if(docType === docTypes.SPPB) {
            params.no_dokumen = no_dokumen
            params.jenis_dokumen = selectedDocument?.value
            if(selectedDocument?.date_required){
              params.tgl_dokumen = tglDocumentRef.current?.value
            }
            url = '/nle/get-pabean'
          } else {
            params.ref_number = plpValues.ref_number
            params.tanggal_plp = plpDateRef.current?.value
          }
          const response = await apiBearer.post(url, {
           ...params
          })
          const responseData = response?.data?.data
          if (responseData) {
            setValue('receiver_name', responseData?.nama_importir ?? '')
            setValue('receiver_address', responseData?.alamat_importir ?? '')
            setValue(
              'koli',
              responseData?.detil_kemasan[0]?.jumlah_kemasan ?? 0,
            )
            const namaPengangkut = docType === docTypes.PLP ? responseData.nama_pengangkut :  responseData.nama_sarana_pengangkut
            const fl_code = namaPengangkut + responseData.nomor_voy_flight
            setValue('flight_code', fl_code ?? '')
            setValue('weight', responseData?.brutto ?? 0)
            setValue('pu', responseData?.nomor_bc11 ?? '')
            if(docType === docTypes.PLP) {
              setValue('receiver_name', responseData?.gudang_tujuan ?? '')
              setValue('awb', responseData?.detil_kemasan[0]?.nomor_bl_awb ?? '')
            }
            setValue('flight_date', responseData?.tanggal_bc11 ?? '')
            flyDateRef.current.value = responseData?.tanggal_bc11 ?? new Date()
            if (responseData.detil_dokumen) {
              responseData.detil_dokumen.forEach((doc) => {
                const {jenis_dokumen, nomor_dokumen} = doc || {}
                setValue(BEA_DOC_KEYS[jenis_dokumen], nomor_dokumen)
              })
            }
          }
        } catch (error) {}

        setIsSeachingNLE(false)
      }:null

  const dataFormProps = {
    errors,
    control,
    warehouseOptions,
    setSelectedWarehouse,
    selectedWarehouse,
    forwarderOptions,
    setSelectedForwarder,
    selectedForwarder,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isSeachingNLE,
    onSearchNLE,
    docType,
    documentOptions,
    selectedDocument,
    setSelectedDocument,
    setPlpValues,
  }
  const fileFormProps = {
    errors,
    register,
    watch,
    awb_file: detailData?.data?.awb_file ?? null,
    hawb_file: detailData?.data?.hawb_file ?? null,
    sppb_file: detailData?.data?.sppb_file ?? null,
    do_file: detailData?.data?.do_file ?? null,
    isEdit: !!id,
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Cdo' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateCdo : _createCdo)}>
              <Card>
                <CardBody className='card-body'>
                  <h4 className=''>Create CDO Processing</h4>
                  <div className='d-flex my-3'>
                  <FormGroup check>
                      <Input
                        name='doc_type'
                        onChange={() =>
                          setDocType(docTypes.SPPB)
                        }
                        type='radio'
                        id='1'
                        className='me-3'
                        checked={docType=== docTypes.SPPB}
                      />
                      <Label check htmlFor='1' className='text-uppercase'>
                        SPPB
                      </Label>
                    </FormGroup>
                    <div style={{width:50}}/>
                    <FormGroup check>
                      <Input
                        name='doc_type'
                        onChange={() =>
                          setDocType(docTypes.PLP)
                        }
                        type='radio'
                        id='2'
                        className='me-3'
                        checked={docType=== docTypes.PLP}
                      />
                      <Label check htmlFor='2' className='text-uppercase'>
                        PLP
                      </Label>
                    </FormGroup>
                  </div>
                  <DataFormCDO
                    {...dataFormProps}
                    ref={{kwitansiDateRef, flyDateRef, plpDateRef, tglDocumentRef}}
                  />
                  <h4 className='mt-4'>Dokumen</h4>
                  <FileFormCDO {...fileFormProps} />
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/cdo' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={loadingUpdate || loadingCreate}>
                    {loadingUpdate || loadingCreate ?  "Loading.." : (id ? "Update" : "Create")}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
