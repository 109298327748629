import BreadCrumb from 'Components/Common/BreadCrumb'
import {truckBookingRQ} from 'Hooks/Queries'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import OpenMap from 'Components/Map/Map'
import {
  CreateLeafIcon,
  RoutingAdd,
  degToRad,
  getBearing,
  radToDeg,
} from 'Components/Map/MapRouting'
import {Marker} from 'react-leaflet'
import {logStatus} from 'Components/TruckingOrder/helpers'
import { featureCollection, nearestPoint, point } from '@turf/turf'

const TimelineItem = ({date, status_name, notes, active}) => (
  <li className={'event ' + (active ? 'active' : '')} data-date={date}>
    <h3>{status_name}</h3>
    <p>{notes}</p>
  </li>
)

const TimeLine = ({logs}) => (
  <div className='card'>
    <div className='card-body'>
      <div id='content'>
        <ul className='timeline-c'>
          {logs.map((log, i) => (
            <TimelineItem {...log} key={i} active={i === 0} />
          ))}
        </ul>
      </div>
    </div>
  </div>
)

const MapDetail = () => {
  const {id} = useParams()
  const [refetchInterval, setRefetchInterval] = useState(1000 * 60)
  const {data, status, isLoading, isFetching} =
    truckBookingRQ.useGetData(id)(refetchInterval)

  const [track, setTrack] = useState({
    trackLatitude: null,
    trackLongitude: null,
  })
  const {
    code,
    last_log_status,
    origin_longitude,
    destination_longitude,
    origin_latitude,
    destination_latitude,
    tracking,
    logs = [],
    sender_address,
    lead_time,
  } = data?.data ?? {}

  const {latitude, longitude} = tracking ?? {}
  const {trackLatitude, trackLongitude} = track ?? {}

  const [waypoints, setWayPoints] = useState([])
  const [rotationAngle, setRotationAngle] = useState(0)
  const [center, setCenter] = useState([-6.2, 106.816666])

  const getMidPoint = (lat1, lon1, lat2, lon2) => {
    const dLon = degToRad(lon2 - lon1)

    //convert to radians
    lat1 = degToRad(lat1)
    lat2 = degToRad(lat2)
    lon1 = degToRad(lon1)

    const Bx = Math.cos(lat2) * Math.cos(dLon)
    const By = Math.cos(lat2) * Math.sin(dLon)
    const lat3 = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By),
    )
    const lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)

    //print out in degrees
    return [radToDeg(lat3), radToDeg(lon3)]
  }

  //set initial map data
  useEffect(() => {
    if (!isLoading && status === 'success') {
      setWayPoints([
        {lat: origin_latitude, long: origin_longitude},
        {lat: destination_latitude, long: destination_longitude},
      ])
      const bearing = getBearing(
        {lat: origin_latitude, long: origin_longitude},
        {lat: destination_latitude, long: destination_longitude},
      )
      setRotationAngle(bearing)

      const midPoint = getMidPoint(
        origin_latitude,
        origin_longitude,
        destination_latitude,
        destination_longitude,
      )
      setCenter(midPoint)
    }
  }, [
    status,
    isLoading,
    origin_latitude,
    origin_longitude,
    destination_latitude,
    destination_longitude,
  ])

  //refetch will affect car position only
  useEffect(() => {
    if (last_log_status === 'Done' && !isFetching) setRefetchInterval(false)
  }, [last_log_status, isFetching])

  useEffect(() => {
    console.log('change', new Date(), {latitude, longitude})
    if (!isFetching  && latitude && longitude){
      
      const carPoint = point([latitude, longitude]);

      // Create a Turf.js featureCollection from the route
      const routeLine = featureCollection([point([origin_latitude,origin_longitude]), point([destination_latitude, destination_longitude])]);
      
      // Find the nearest point on the route to the car's position
      const nearest = nearestPoint(carPoint, routeLine);
      // nearest.geometry.coordinates
      setTrack(nearest.geometry.coordinates)
    }
  }, [latitude, longitude, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Truck Booking Detail' />
        <Card>
          <CardBody className='card-body'>
            <Row>
              <Col xl={5} sm={12} className='px-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h2 style={{color: '#727E8C'}} className='fw-semibold'>
                    {code}
                  </h2>
                  {logStatus(last_log_status)}
                </div>
                <div className='d-flex justify-content-between align-items-end mt-3'>
                  <p style={{color: '#727E8C'}} className='mb-0'>
                    Dikirim dari
                  </p>
                  {sender_address ?? '-'}
                </div>
                <div className='d-flex justify-content-between align-items-end mt-3'>
                  <p style={{color: '#727E8C'}} className='mb-0'>
                    Est. Diterima
                  </p>
                  {lead_time ?? '-'}
                </div>
                <TimeLine logs={logs} />
              </Col>
              <Col xl={7} sm={12}>
                <OpenMap center={center} zoom={1} height={700}>
                  <RoutingAdd waypoints={waypoints} />
                  {trackLatitude && trackLongitude ? (
                    <Marker
                      key={rotationAngle}
                      position={[trackLatitude, trackLongitude]}
                      icon={CreateLeafIcon({url: '/assets/icons/car.png'})}
                      rotationOrigin='center'
                      rotationAngle={rotationAngle}
                    />
                  ) : null}
                </OpenMap>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default MapDetail
